.ar-content{
  /* styles for arabic language */
  direction: rtl;
  font-family:Arial, Helvetica, sans-serif;
}
body {
  font-size: 13px !important;
}
.App {
  text-align: left;
  /*color: #667089;*/
}
/*h1, h2, h3, h4, .appContent.injaz h2.ui.header {
  color: #667089;
}
.appContent.injaz h2.ui.header, .appContent.injaz h3.ui.header  {
  color: #667089 !important;
}
.injaz-home-news h3 {
  color: #667089 !important;
}*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.injaz-logo {
  padding: 5px !important;
}
.injaz-logo img {
  height: 38px !important;
    width: auto !important;
}
.image.injaz-powered {
  position: absolute !important;
  bottom: 0px !important;
  left: 15px;
  background-color: #005596 !important;
  padding-bottom: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.access-denied {
  background-image: url(/public/img/denied_access.jpg) !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 100vh !important;
  color: #005596 !important;
}
.access-denied button.ui.basic.inverted.button.sidenav-btn {
  color: #005596 !important;
  border: 1px solid #005596 !important;
  margin-left: 0px !important;
}
.access-denied h3.ui.header.sidebar-welcome {
  color: #005596 !important;
  text-align: left !important;
}
.access-denied .ui.icon.header {
  text-align: left !important;
  color: #005596 !important;
}
.access-denied-content {
  text-align: left !important;
  margin: 0 auto !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*Side Nav Bar*/
.sidebar-scroll-menu {
  position: inherit;
  height: calc(100vh - 131px);
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 30px;
  left: 0;
  overflow-x: hidden;
}
a.sidebar-logo {
  float: left;
  width: 50%;
    padding: 5px;
}
button.ui.icon.right.floated.button.navbarmenu-btn {
  height: 50px;
  width: 50px;
}
.sidebar-welcome {
  color: #fff !important;
}
.item.personal-info {
  padding-top: 30px !important;
}
.sidebar-burger {
  border-radius: initial !important;
  margin-right: 0px !important;
  padding-bottom: 15px !important;
}
.injaz-sidebar .navbarmenu-btn {
  background: #00aeef !important;
  color: #fff !important;
  border-radius: initial !important;
}
.injaz-sidebar .sidenavbar-links .item {
  text-align: left !important;
  color: #fff !important;
}
.sidenavbar-links a.header.item.active {
  color: #fbad1d !important;
}
.injaz-main .ui.container.appContent.injaz, .ui.container.injaz-main-public {
  width: auto;
  background: #f3f3f3;
  border-left: 56px solid #005596;
  /*min-height: 900px;*/
  min-height: calc(100vh - 50px);
  padding: 20px 20px 20px 20px;
  background-image: url(/public/img/INJAZ_brand_lines.png) !important;
  background-repeat: no-repeat;
  background-position: top right !important;
}

.ui.injaz-topnavbar.menu {
  background: #e7e7e7 !important;
  margin: 0px !important;
  border-radius: initial !important;
}
.item .navbar-btn {
  border-radius: 20px !important;
  float: right;
  color: #005596 !important;
}

.sidenavbar-btn {
  border-radius: 20px !important;
  color: #fff !important;
}
.injaz-accordion div.title {
  padding: 0px !important;
  font-weight: 700;
}
.injaz-accordion i.dropdown.icon {
  float: right !important;
  -webkit-transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important;
    -o-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
}
.topnavbar-public .ui.injaz-topnavbar.menu {
  border-left: 56px solid #00aeef;
}
.injaz-main .injaz-sidebar {
  background-color: #005596 !important;
  width: 250px !important;
}
.injaz-main .sidebar-burger {
  background-color: #00aeef !important;
  color: #fff !important;
}
.injaz-main .injaz-sidebar .sidebar-closebtn {
  background-color: #00aeef !important;
  color: #fff !important;
}
.sidebar-footer {
  position: fixed;
  bottom: 0;
  background-color: #005596 !important;
  width: 100%;
}
/*About*/
.home-clickable {
  margin-bottom: 18px;
}
.ui.red.segment.full-injaz-news-ar {
  text-align: right;
  font-size: 17px;
}
/*Breadcrumbs*/
.injaz-breadcrumbs {
  border: 1px solid #cbd2d9;
  border-radius: 0.3rem;
  display: inline-flex;
  overflow: hidden;
}

.injaz-breadcrumbs__item {
  background: #fff;
  color: #333;
  outline: none;
  padding: 0.75em 0.75em 0.75em 1.25em;
  position: relative;
  text-decoration: none;
  transition: background 0.2s linear;
}

.injaz-breadcrumbs__item:hover:after,
.injaz-breadcrumbs__item:hover {
  background: #edf1f5 !important;
}

.injaz-breadcrumbs__item:focus:after,
.injaz-breadcrumbs__item:focus,
.injaz-breadcrumbs__item.is-active:focus {
  background:#005596 !important;
  color: #fff !important;
}

.injaz-breadcrumbs__item:after,
.injaz-breadcrumbs__item:before {
  background: white;
  bottom: 0;
  -webkit-clip-path: polygon(50% 50%, -50% -50%, 0 100%);
          clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  transition: background 0.2s linear;
  width: 1em;
  z-index: 1;
}

.injaz-breadcrumbs__item:before {
  background: #cbd2d9;
  margin-left: 1px;
}

.injaz-breadcrumbs__item:last-child {
  border-right: none;
}

.injaz-breadcrumbs__item.is-active {
  background: #edf1f5;
}
/*Home*/
.injaz-home-news img {
  width: 95%;
  float: right;
}
h2.ui.header.home-header {
  padding-top: 10px;
}
.injaz.card-text {
  font-size: 1.2rem !important;
}
.eleven.wide.column.injaz-public-row1-col1 .slick-next:before, .eleven.wide.column.injaz-public-row1-col1 .slick-prev:before {
  color: #005596 !important;
}
.eleven.wide.column.injaz-public-row1-col1 .slick-prev {
  left: -20px !important;
}
.eleven.wide.column.injaz-public-row1-col1 .slick-next {
  right: -20px !important;
}
.eleven.wide.column.injaz-public-row1-col1 .slick-dots li button:before {
  font-size: 12px;
  color: #005596 !important;
}
/*Cards*/
.injaz-main-public .injaz-public-row1 .injaz-public-row1-col1 .ui.card, .injaz-main-public .injaz-public-row1 .injaz-public-row1-col2 .ui.card,
.App.injaz-main .injaz-public-row1 .injaz-public-row1-col1 .ui.card, .App.injaz-main .injaz-public-row1 .injaz-public-row1-col2 .ui.card {
  width: 100% !important;
}
/*.injaz-public-row1-col1 img, .injaz-public-row1-col2 img {
  max-height: 308px !important;
}*/
.injaz-home-more {
  color: #fff !important;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 0 0 1px #fff inset!important;
  border-radius: 20px;
  padding: 3px 8px;
}
/*.injaz-home-more:hover {
  font-weight: bolder !important;
}*/
.injaz-public-row1-col3 .injaz-public-row1-col3-card1 .more-btn, .injaz-public-row1-col3 .injaz-public-row1-col3-card2 .more-btn {
  border-radius: 20px !important;
  float: right;
}

.injaz-public-row1-col3 .card-overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity:1;
  color: #fff;
  font-size: 20px;
  padding: 20px;
}
.row1-rtcol-1 p, .row1-rtcol-2 p {
    color: #fff;
}
.injaz-public-row1-col3-card1 .card-overlay {
	background: rgba(0, 43, 112, 0.5); /* Blue see-through */
}
.injaz-public-row1-col3-card2 .card-overlay {
	background: rgba(199, 35, 49, 0.5); /* Black see-through */
}
.content.inj-home-rt-column.firstcol {
	background: rgba(0, 43, 112, 0.5) !important; /* Blue see-through */
  min-height: 100% !important;
  color: #fff !important;
  width: 100% !important;
}
.content.inj-home-rt-column.secondcol {
	background: rgba(199, 35, 49, 0.5) !important; /* Blue see-through */
  min-height: 100% !important;
  color: #fff !important;
  width: 100% !important;
}
.content.inj-home-rt-img {
  min-height: 204px;
}
.content.inj-home-rt-column .header {
  font-size: 1.71428571rem !important;
  margin-bottom: 15px !important;
}
.content.inj-home-rt-column .description {
  font-size: 17px;
}
.content.inj-home-rt-column .header, .content.inj-home-rt-column .description {
  color: #fff !important;
}
.content.inj-home-rt-column .more-btn {
  border-radius: 20px !important;
  float: right;
}
/*Row 2 Cards*/
.injaz-home-search input {
  border-radius: initial !important;
}
.injaz-home-search .input {
  width: 100%;
}
.injaz-home-search i.search.icon {
  color: #005596 !important;
}
.injaz-public-row2 .header {
  margin-bottom: 5px !important;
}
.injaz-public-row2 .injaz-row2-col1 .extra.content {
  background-color: #d82231;
  color: #fff !important;
}
.injaz-public-row2 .injaz-row2-col2 .extra.content {
  background-color: #00aeef;
  color: #fff !important;
}
.injaz-public-row2 .injaz-row2-col3 .extra.content {
  background-color: #fbad1d;
  color: #fff !important;
}
.injaz-public-row2 .injaz-row2-col4 .extra.content {
  background-color: #005596;
  color: #fff !important;
}
.injaz-public-row2 .injaz-row2-col5 .extra.content {
  background-color: #d82231;
  color: #fff !important;
}
.injaz-public-row2 .extra.content a {
  color: #fff !important;
  float: right !important;
  cursor: pointer;
}
/*Right Sidebar*/
.injaz-rightsidebar {
  padding: 15px;
  text-align: left !important;
}
.sidenav-btn {
  border-radius: 20px !important;
}
.personal-info p {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*Profiles*/
.profile-info .item {
  margin-bottom: 15px;
  word-break: break-all;
}
/*Forms*/
.injaz-srch input {
  padding-top: .6em !important;
  padding-bottom: .65em !important;
}
.injaz-srch button.ui.button {
  padding: .78571429em 0.4em .78571429em .78571429em !important;
}
.injaz-forms-btns .injaz-srch input[type="text"] {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.injaz-forms-btns .injaz-srch button.ui.button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #2185d0!important;
    color: #fff !important;
}
.injaz-forms-segment-nopad {
  padding: 0px !important;
}
.injaz-form-pad {
  padding: 2em 1em 1em 1em !important;
}

.injaz-form-pad .ui.label.label {
  margin: 0 0 0.5rem 0 !important;
}
/*.checkbox-align .field {
  vertical-align: bottom !important;
    bottom: 14px !important;
    position: absolute;
}*/

.form-btn-container {
  padding: 10px 15px 10px 15px !important;
}
.injaz-forms-btns-incl {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
  z-index: 100;
  margin-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  /*border-top: 2px solid #fbbd08!important;*/
  border-left: none !important;
  border-right: none !important;
}
.injaz-forms-cancel-btn-container {
  position: sticky;
  top: -1px;
  z-index: 101;
}
.injaz-forms-cancel-btn-container .injaz-forms-cancel-btn { 
  position: absolute;
  left: 100px;
  top: 13px;
}
.item.injaz-srch {
  /*right: 0 !important;
  position: absolute !important;*/
}
.injaz-forms-btns button.ui.basic.disabled.button, .injaz-users-forms-btns button.ui.basic.disabled.button {
  -webkit-box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) inset !important;
}
.injaz-forms-btns button.ui.basic.disabled.button:hover, .injaz-users-forms-btns button.ui.basic.disabled.button:hover {
  -webkit-box-shadow: 0 0 0 1px rgb(34 36 38 / 35%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
    box-shadow: 0 0 0 1px rgb(34 36 38 / 35%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
}
.injaz-forms-segment, .segment.injaz-manage-segment {
  margin-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.injaz-form-pad .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after, .injaz-form-pad .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  left: -16px !important;
}
.extra-row-align {
  padding-top: 0px !important;
}
.ui.multiple.dropdown>.text {
  margin: 0.45238095em 0.64285714em 0.45238095em 0.64285714em !important;
}
.appContent.injaz .injaz-forms-segment-pad .ui.multiple.selection.dropdown {
  padding: 0px !important;
}
.injaz-forms-segment-pad .ui.multiple.selection.dropdown a.ui.label {
  padding: 7px !important;
  margin: 5px !important;
  background-color: #f0f0f0!important;
}
.error.field .ui.red.basic.label {
  color: #db2828 !important;
  border: none !important;
}
.injaz-forms-btns {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
  z-index: 100;
  margin-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top: 2px solid #fbbd08!important;
  margin-top: 0px !important;
  display: block !important;
}
.injaz-users-forms-btns {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
  z-index: 100;
  margin-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top: 2px solid #d82231 !important;
}
.flt-right {
  float: right;
}
.flt-left {
  float: left;
}
.injaz-forms-segment-pad h3.ui.header {
  color: #005596;
  font-size: 20px;
}
.addeditform label {
  font-weight: bold;
  padding-right: 20px;
}
.addeditform input, .addeditform select {
  margin-bottom: 15px !important;
}
.grid-left {
  padding-right: 2rem !important;
}
.grid-right {
  padding-left: 2rem !important;
}
.appContent.injaz .ui.divider {
  width: 100% !important;
  margin-bottom: 0px !important;
}
.addeditform .label {
  margin: 0 0 .28571429rem 0 !important;
}
.ui.raised.segments.injaz-forms-segment-pad {
  border-top-left-radius: 0.28571429rem !important;
  border-top-right-radius: 0.28571429rem !important;
  margin-top: 0px !important;
}
.ui.raised.segments.injaz-forms-segment .ui.segment, .ui.raised.segments.injaz-forms-segment-pad .ui.segment {
  padding-top: 25px;
}
.ui.raised.segments.injaz-forms-segment .label, .ui.raised.segments.injaz-forms-segment-pad .label, .ui.raised.segments.injaz-forms-segment label, .ui.raised.segments.injaz-forms-segment-pad label, .AddEditSessionSidebar label, .AddEditSessionSidebar .label {
  background-color: transparent !important;
  padding: 0px 0px 0px 0px !important;
  color: #000 !important;
  font-weight: 700;
  font-size: 1em;
}
.AddEditSessionSidebar label, .AddEditSessionSidebar .label {
  font-size: 1em !important;
}
.AddEditSessionSidebar, .AddMemberSidebar {
  background-color: #fff !important;
}
.ui.raised.segments.injaz-forms-segment .ui.labeled input, .ui.raised.segments.injaz-forms-segment-pad input, .ui.raised.segments.injaz-forms-segment .selection, .ui.raised.segments.injaz-forms-segment-pad .selection, .AddEditSessionSidebar input, .AddEditSessionSidebar select {
  display: block;
  height: auto;
  width: 100% !important;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15) !important;
  border-radius: .28571429rem !important;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
  padding: .62em 1em !important;
  color: rgba(0,0,0,.87) !important;
  -webkit-transition: color .1s ease,border-color .1s ease;
  transition: color .1s ease,border-color .1s ease;
  margin-bottom: 15px !important;
}
.AddEditSessionSidebar  .ui.form input[type=checkbox], .injaz-forms-segment .ui.form input[type=checkbox], .injaz-forms-segment form input[type=checkbox], .injaz-forms-segment-pad .ui.form input[type=checkbox], .injaz-forms-segment .ui.checkbox input[type=checkbox], .ui.raised.segments.injaz-forms-segment-pad input[type=checkbox] {
  width: inherit !important;
    margin-right: 5px;
    margin-top: 4px;
    float: left;
}
.addeditsessions-close-btn {
  position: absolute;
    margin-top: -36px !important;
    margin-left: 80px !important;
}
.ui.raised.segments.injaz-forms-segment .ui.labeled.input, .ui.raised.segments.injaz-forms-segment-pad .ui.labeled.input, .AddEditSessionSidebar .ui.labeled.input {
  display: block;
}
.ui.raised.segments.injaz-forms-segment .field, .ui.raised.segments.injaz-forms-segment-pad .field {
  display: block;
}
.ui.raised.segments.injaz-forms-segment textarea, .ui.raised.segments.injaz-forms-segment-pad textarea {
  height: auto;
  width: 100% !important;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15) !important;
  border-radius: .28571429rem !important;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
  padding: .62em 1em !important;
  color: rgba(0,0,0,.87) !important;
  -webkit-transition: color .1s ease,border-color .1s ease;
  transition: color .1s ease,border-color .1s ease;
  margin-bottom: 15px !important;
}
/*Course Details*/
.injaz-course-startenddate {
  display: flex;
}
.injaz-course-details span {
  font-weight: 100;
}
.injaz-course-details div {
  margin-bottom: 10px !important;
  font-weight: bold;
}
.injaz-course-name {
  font-size: 2em;
  font-weight: bold;
  word-break: break-word;
}
span.injaz-course-end {
  padding-left: 20px;
  font-weight: bold !important;
}
.injaz-course-days span {
  padding: 5px;
}
.injaz-course-days span:not(:last-child):after {
  content: ",";
}
span.injaz-program-label:after {
  content: none !important;
}
span.injaz-course-start {
  font-weight: bold !important;
}
.session-select select {
  width: auto !important;
}
.injaz-take-attendance button.ui.blue.basic.button.attendance_btn {
  margin-top: -36px !important;
}
/*.addedit-sessions select, .addedit-sessions input,  .AddEditSessionSidebar input, .AddEditSessionSidebar select {
  margin-bottom: 15px !important;
}*/
.sessions-msg.ui.warning.message .header {
  font-size: 1em;
}
.ui.warning.message {
  display: inherit !important;
}
.AddEditSessionSidebar .ui.container, .AddMemberSidebar .ui.container {
  padding: 20px;
}
.attendance_btn {
  float: right;
  margin-bottom: 20px !important;
}

/*My Courses*/
.injaz-mycourses {
  background: #fff;
  box-shadow: 0 1px 2px #ccc;
}
.injaz-mycourses .injaz-thematic-area {
  margin-bottom: 5px;
}
.mycourses-btns {
  padding: 10px 10px 0px 10px;
  width: 100% !important;
  border-top: 1px solid rgba(34,36,38,.1) !important;
}
.attendance-btn button.ui.button {
  border: 1px solid #fff;
  opacity: 0.6;
  padding: 5px 8px;
}
button.button.course-btn, .course-btn {
  margin-bottom: 20px;
  width: -webkit-fill-available;
}
/*button.button.course-btn, .course-btn {
  float: right;
}*/
.label.injaz-thematic-area {
  margin-bottom: 10px !important;
}
.injaz-mycourses div.injaz-lbl  {
  padding: 5px 0px 5px 0px;
}
span.injaz-lbl {
  font-weight: 700;
}
/*Program Details*/

.change-program-image {
  position: inherit;
    color: #FFFFFF !important;
    padding: 8px;
    text-align: center;
    line-height: 19px;
    vertical-align: middle;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    cursor: pointer;
    bottom: 34px;
}
.change-program-image:hover {
  background: #000;
}
.change-program-image a {
  color: #fff !important;
}
.injaz-program-information a.ui.blue.basic.button.course-btn {
  background-color: #f3f3f3 !important;
}
.injaz-program-information div, .injaz-program-details .segment div {
  padding: 5px 0px 5px 0px;
}
.injaz-program-information img.ui.image {
  width: -webkit-fill-available !important;
}
span.injaz-program-label-detail {
  padding: 2px;
  word-break: break-all !important;
}
span.injaz-lbl-detail:not(:last-child):after, span.injaz-program-label-detail:not(:last-child):after {
  content: "," !important;
}
span.injaz-program-label {
  font-weight: bold;
  margin-right: 5px;
  padding: 0px !important;
}
span.injaz-lbl-detail {
  padding-right: 4px;
}
span.injaz-lbl-detail:not(:last-child):after {
  content: ",";
}
span.injaz-program-spacing {
  margin-right: 10px;
}
.injaz-progranm-shortname {
  font-weight: bold;
  font-size: 1.3em;
}
.course-settings {
  width: 50px !important;
  text-align: center;
  float: right;
  margin-bottom: 20px;
}
.opac-image {
  display: inherit;
    position: relative!important;
    font-size: 0!important;
}
.opac-image div {
  position: absolute!important;
    top: 0!important;
    left: 0!important;
    z-index: 3!important;
    -webkit-transition: all .5s ease .1s;
    transition: all .5s ease .1s;
}
.opac-image .opac-img {
  background-color: #005596;
  opacity: 0.5;
}
/*Drag and Drop Sidebar*/
.injaz-user-dragdrop {
  margin-bottom: 15px;
}

/*Members*/
a.ui.small.icon.primary.right.floated.right.labeled.button.add-member-btn {
  margin-bottom: 2px;
}
.members-selection td .ui.header .content .sub.header {
  word-break: break-all;
}
.table.members-selection {
  min-height: 7em !important;
  padding-top: 10px !important;
}
.members-tbls .add-member-btn {
  margin-bottom: 15px !important;
}
.injaz-sub {
  display: flex;
}
.injaz-sub a {
  margin-right: 5px;
  margin-bottom: 5px;
}
.sidebar-btns {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  height: 50px;
  padding-top: 5px;
}
.members-sidebar-container {
  min-height: calc(100vh - 170px);
}
/*Language Modal*/
.lang-modal li {
  display: flex !important;
  margin-bottom: 20px;
}
/*.lang-modal :before {
  background-color: #005596 !important;
  border-radius: 20px;
  color: #fff !important;
  font-weight: 700;
  width: 22px;
  height: 22px;
  padding: 2px 7px 2px 0px;
}*/
.lang-modal .li-order-number {
  background-color: #005596 !important;
  opacity: 0.80;
  border-radius: 20px;
  color: #fff !important;
  font-weight: 700;
  width: 35px;
  height: 35px;
  padding: 3px 1px 2px 11px;
  margin-right: 10px;
  font-size: 20px;
}
.lang-modal .middle.aligned.content {
  padding-top: 8px;
}
.lang-modal .lang-btn {
  margin-left: 10px;
}
.lang-modal .lang-modal-img {
  margin-left: 45px !important;
}
.lang-modal .lang-switch-item {
  margin:0px 4px;
}
/*Slider*/
.slick-slider button.slick-arrow.slick-prev, .slick-slider button.slick-arrow.slick-next {
  display: none !important;
}
.news-slider-text {
  max-height: 304px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*News Page*/
.full-news-img img {
  width: 50%;
}
.full-injaz-news {
  font-size: 17px !important;
  padding: 20px 50px;
  text-align: justify;
  text-justify: inter-word;
}
/*Demo to be removed*/
.hide-demo {
  display: none !important;
}
.injaz-sidebar .lang-btn button.ui.basic.button {
  color: #fff !important;
  border: 1px solid #fff !important;
}
.injaz-sidebar .lang-btn button.ui.basic.button:hover {
  color:#005596 !important;
  border: 1px solid #fff !important;
}
/*Loader*/
.segment.injaz-loader-tbl {
  margin-top: 0px !important;
  min-height: 100px;
}
.segment.injaz-loader-tbl .ui.active.transition.visible.inverted.dimmer {
  display: flex !important;
  z-index: 1;
}
/*Empty List Message*/
.injaz-empty-list-msg {
  margin-top: 0px !important;
}
.message.empty-list-msg .header, .attendance-msg .header {
  font-size: 1em !important;
}
.ui.message.attendance-msg {
  margin: 10px !important;
}
.sidenavbar-links > div.item .active.title, .sidenavbar-links > div.item .title {
  color: #fff !important;
}
/*Reports*/
.reports-hub div.header {
  height: 3em;
}
.reports-hub img {
  display: block !important;
}
#semester-tbl.reports-tbl {
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
  height: calc(100vh - 255px);
}
#volunteer-tbl.reports-tbl {
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
  max-height: calc(100vh - 190px);
}
#semester-tbl #tblReport thead tr th:first-child, #semester-tbl #tblReport thead tr th:nth-child(2),
#semester-tbl #tblReport tbody tr td:first-child, #semester-tbl #tblReport tbody tr td:nth-child(2)
/*, #tblReport thead tr th:nth-child(3), #tblReport tbody tr td:nth-child(3),
#tblReport thead tr th:nth-child(4), #tblReport tbody tr td:nth-child(4),
#tblReport thead tr th:nth-child(5), #tblReport tbody tr td:nth-child(5),
#tblReport thead tr th:nth-child(6), #tblReport tbody tr td:nth-child(6),
#tblReport thead tr th:nth-child(7), #tblReport tbody tr td:nth-child(7)*/
{
  position: sticky;
  left: 0;
  z-index: 100;
  background-color: #fff !important;
}



#semester-tbl #tblReport th.alt-color:nth-child(n+3) {
  background-color: #daeef3;
}
#semester-tbl #tblReport th.alt-color-sub:nth-child(2n+2), #semester-tbl #tblReport td.alt-color-cell:nth-child(2n+2) {
  background-color: #daeef3 !important;
}
#semester-tbl #tblReport th.alt-color-sub:nth-child(2n+3), #semester-tbl #tblReport td.alt-color-cell:nth-child(2n+3) {
  background-color: #f2dcdb !important;
}
#semester-tbl #tblReport th.alt-color:nth-child(2n+3) {
  background-color: #f2dcdb !important;
}
#semester-tbl #tblReport th.alt-color-sub:nth-child(4n+3), #semester-tbl #tblReport td.alt-color-cell:nth-child(4n+3) {
  background-color: #daeef3 !important;
}
#semester-tbl #tblReport th.alt-color-sub:nth-child(4n+2), #semester-tbl #tblReport td.alt-color-cell:nth-child(4n+2) {
  background-color: #f2dcdb !important;
}
/*Tables*/
.injaz-manage-segment td {
  word-break: break-word;
}
td.injaz-user-email {
  direction: ltr !important;
}
/*Injaz Certificates*/
.injaz-certificate-wrapper {
  background-color: #fff;
}
.cert-bg img.ui.small.image {
  position: absolute;
  width: 1127px;
  height: 794px !important;
}
#pdf.injaz-certificate-wrapper { 
  width: 1123px;
  height: 794px;
  margin: 0 auto !important;
}
.injaz-certificate-container {
  width: 1123px;
  height: 794px;
  background: transparent !important;
}

.cert-logos {
  height: 80px;
}
.course-pub-date {
  margin-bottom: 0.25em;
}
/*.injaz-certificate-container {
  background-image: url(/public/img/Injaz-Certificate-bg.png) !important;
  background-repeat: no-repeat !important;
  background-position: top right !important;
  background-size: auto 100% !important;
}*/

.injaz-certificate {
  padding: 20px 30px;
}
.injaz-certificate h1.ui.header {
  color: #00aeef !important;
  text-decoration-line: underline;
  margin-bottom: 30px;
  margin-left: 75px;
  font-size: 1.8rem;
}
.injaz-cert {
  text-align: right;
}
p.cert-lbl {
  color: #005596;
  font-size: 1.7em;
}
p.cert-input {
  border-bottom: 1px solid #005596;
  font-size: 1.4em;
}
img.ui.image.injaz-cert-design {
  height: 792px;
  max-width: 145px;
  padding-right: 0px;
  margin-right: 0px;
  right: 0px;
  position: absolute;
}
.injaz-certificate-wrapper .thirteen.wide.column, .injaz-certificate-wrapper .three.wide.column {
  padding: 0px !important;
}
/*Institutions*/
.ui.yellow.segment.injaz-imported-students {
  width: 100%;
  margin: 1rem;
}
/*Import*/
.ui.ordered.list.import-steps>.item:before {
  background-color: #005596 !important;
  opacity: 0.80;
  border-radius: 20px;
  color: #fff !important;
  font-weight: 700;
  width: 25px;
  height: 25px;
  padding: 4px 12px 2px 8px;
  margin-right: 10px;
  font-size: 14px;
}
.import-steps .li-order-number {
  margin: 0px 20px 0px 20px;
  padding-top: 5px;
}
.import-steps .item {
  margin-bottom: 15px;
}
.injaz-imported-students div.import-error, .injaz-imported-students .icon {
  color: #912d2b;
}
.ui.error.message.import-error-msg {
  margin: 10px 0px 10px 0px;
}
.import-steps .li-order-number button {
  margin-top: 5px !important;
}
/*Arabic Styles*/
/*Date Picker*/
.injaz-main.ar-content .react-datepicker-popper {
  inset: 0px auto !important;
    transform: translate3d(0px, 69.3333px, 0px) !important;
}
.injaz-main.ar-content .injaz-form-pad .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after, 
.injaz-main.ar-content .injaz-form-pad .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  right: 178px !important;
}
/*Breadcrumbs*/
.injaz-main.ar-content .injaz-breadcrumbs__item:before, .injaz-main.ar-content .injaz-breadcrumbs__item:after {
  transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
}
.injaz-main.ar-content .injaz-breadcrumbs__item:after, .injaz-main.ar-content .injaz-breadcrumbs__item:before {
  background: white;
  bottom: 0;
  clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  right: 100%  !important;
  left: initial !important;
  position: absolute;
  top: 0;
  transition: background 0.2s linear;
  width: 1em;
  z-index: 1;
}
.injaz-main.ar-content .injaz-breadcrumbs__item {
  padding: 0.75em 1em 0.75em 1.25em !important;
}
/*Sidebar*/
.injaz-main.ar-content .injaz-accordion div.title {
  padding: 0px !important;
  font-weight: 700;
  text-align: right !important;
}
.injaz-main.ar-content .injaz-accordion i.dropdown.icon {
  float: left !important;
  -webkit-transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important;
    -o-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
}
.injaz-main.ar-content .injaz-forms-btns i.download.icon {
  margin-left: 0.4em !important;
  margin-right: -0.4em !important;
}
.injaz-main.ar-content .ui.raised.segments.injaz-forms-segment .ui.labeled input,.injaz-main.ar-content .ui.raised.segments.injaz-forms-segment-pad input,.injaz-main.ar-content .ui.raised.segments.injaz-forms-segment .selection,.injaz-main.ar-content .ui.raised.segments.injaz-forms-segment-pad .selection,.injaz-main.ar-content .AddEditSessionSidebar input,.injaz-main.ar-content .AddEditSessionSidebar select, .injaz-main.ar-content .AddEditSessionSidebar .selection {
  text-align: right !important;
}
.injaz-main.ar-content .AddEditSessionSidebar .selection {
  padding: .78571429em 1em .78571429em 2.1em !important;
}
.injaz-main.ar-content .AddEditSessionSidebar .addeditsessions-close-btn {
  position: absolute;
  margin-top: -36px !important;
  margin-right: 80px !important;
  margin-left: initial !important;
}
.injaz-main.ar-content .session-select button.ui.blue.basic.button.attendance_btn {
  float: left;
}
.injaz-main.ar-content .session-select-btns {
  float: left;
}
.injaz-main.ar-content .ui.selection.dropdown>.delete.icon, .injaz-main.ar-content .ui.selection.dropdown>.dropdown.icon, .injaz-main.ar-content .ui.selection.dropdown>.search.icon {
  left: 1em !important;
  right: initial;
}
.injaz-main.ar-content, .injaz-main-public.ar-content  {
  text-align: right;
}
.injaz-main.ar-content .ui.menu .right.item.auth-btn::before {
  left: auto !important;
  right: 0 !important;
}
.injaz-main.ar-content .ui.container.appContent.injaz, .injaz-main.ar-content .ui.container.injaz-main-public {
  background-image: url(/public/img/INJAZ_brand_lines_ar.png) !important;
  background-repeat: no-repeat;
  background-position: top left !important;
}
.injaz-main.ar-content .sidebar-burger, .injaz-main.ar-content .ui[class*="right floated"].button, .ui[class*="right floated"].buttons {
  margin-left: 0px !important;
}
.injaz-main.ar-content .sidenavbar-links a.item, .injaz-main.ar-content .sidenavbar-links .header {
  text-align: right !important;
}
.injaz-main.ar-content a.sidebar-logo {
  float: right !important;
}
.injaz-main.ar-content button.ui.icon.right.floated.button.navbarmenu-btn {
  float: left !important;
}
.injaz-main.ar-content .ui.container.appContent.injaz, .injaz-main.ar-content ui.container.injaz-main-public {
  border-right: 56px solid #005596 !important;
  border-left: none !important;
}
.injaz-main.ar-content .injaz-sidebar i.arrow.left.icon {
  transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
}
.injaz-main.ar-content .injaz-home-news .grid {
  direction: rtl !important;
}
.injaz-main.ar-content .injaz-home-news img {
  float: left !important;
}
.injaz-main.ar-content .injaz-home-search input {
  text-align: right;
}
.injaz-main.ar-content .injaz-home-search i.search.icon {
  left: 0 !important;
  right: initial !important;
}
.lang-modal.lang-switcher-ar .scrolling.content {
  direction: rtl !important;
}
.lang-modal.lang-switcher-ar .lang-instructions {
  text-align: right;
}
.lang-modal.lang-switcher-ar .li-order-number {
  padding: 3px 1px 2px 11px;
  margin-left: 10px;
}
.lang-modal.lang-switcher-ar .header {
  text-align: right;
}
.injaz-public-row2 img.ui.medium.left.floated.image {
  max-height: 150px;
  min-height: 150px;
}
.injaz-public-row2 .header {
  height: 42px !important;
}
.injaz-main.ar-content .injaz-public-row2 i.arrow.right.icon {
  transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
}
.injaz-main.ar-content .injaz-public-row2 .ui.card .extra.content a {
  float: left !important;
}
.injaz-main.ar-content a.ui.small.icon.primary.right.floated.right.labeled.button.add-member-btn {
  float: left;
  margin-right: 2px;
}
/*Tables*/
.injaz-main.ar-content .change-program-image i.camera.icon {
  margin: 0 0 0 .25rem !important;
}
.injaz-main.ar-content .injaz-manage-segment th, .injaz-main.ar-content .injaz-manage-segment td, .injaz-main.ar-content .injaz-forms-segment th, .injaz-main.ar-content .injaz-forms-segment td {
  text-align: right !important;
}
.injaz-main.ar-content table th, .injaz-main.ar-content table td, .injaz-main.ar-content table th, .injaz-main.ar-content table td {
  text-align: right !important;
}
.injaz-main.ar-content .item.injaz-srch {
  left: 0 !important;
  right: initial !important;
}
.injaz-main.ar-content .item.injaz-srch i.delete.link.icon {
  left: 0;
  right: initial !important;
}
.injaz-main.ar-content .item.injaz-srch input[type="text"] {
  text-align: inherit;
  padding-left: 2.67142857em!important;
  padding-right: 1em !important;
}
.injaz-main.ar-content .session-filled i.red.circle.icon {
  margin-left: 8px;
}
/*Forms*/
.injaz-main.ar-content .injaz-forms-btns .injaz-srch input[type="text"] {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0.28571429rem !important;
  border-bottom-right-radius: 0.28571429rem !important;
}
.injaz-main.ar-content .injaz-forms-btns .injaz-srch button.ui.button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0.28571429rem !important;
  border-bottom-left-radius: 0.28571429rem !important;
  padding-left: 15px;
  padding-right: 15px;
}
.injaz-main.ar-content .injaz-forms-cancel-btn-container .injaz-forms-cancel-btn {
  left: initial!important;
  right: 100px !important;
}
.injaz-main.ar-content .ui.dropdown .menu>.item {
  text-align: right;
}
.injaz-main.ar-content input[type="checkbox"] {
  text-align: right !important;
  float: right !important;
  margin-left: 8px;
  margin-right: 0px !important;
  right: 0 !important;
  left: initial !important;
}
.injaz-main.ar-content .ui.checkbox label:before {
  right: 0 !important;
  left: initial !important;
}
.injaz-main.ar-content .injaz-forms-segment-pad .ui.multiple.selection.dropdown i.delete.icon {
  margin-right: 10px;
}
/*Members*/
.injaz-main.ar-content .members-selection {
  text-align: right !important;
  min-height: 7em !important;
  padding-top: 10px !important;
}
.injaz-main.ar-content .flt-left {
  float: right !important;
}
.injaz-main.ar-content .flt-right {
  float: left !important;
}
.injaz-main.ar-content .members-selection tbody tr td, .injaz-main.ar-content .members-selection thead tr th {
  padding-right: 0.78571429em !important;
}
.injaz-main.ar-content button.add-member-btn {
  float: left;
}
.injaz-main.ar-content .injaz-sub a {
  margin-left: 5px;
  margin-bottom: 5px;
}
/*Course Details*/
.injaz-main.ar-content span.injaz-course-end {
  padding-right: 20px !important;
  padding-left: 0px !important;
  font-weight: bold !important;
}
.injaz-main.ar-content span.injaz-program-spacing {
  margin-right: 10px;
}
.injaz-main.ar-content .attendance_btn {
  float: left !important;
}
.injaz-main.ar-content .injaz-course-days span:not(:last-child):after {
  content: "،";
}
/*Reports*/
.injaz-main.ar-content .reports-hub i.arrow.right.icon {
  transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
}
/*Program*/
.injaz-main.ar-content span.injaz-program-label {
  margin-left: 5px;
  margin-right: initial !important;
}
.injaz-main.ar-content span.injaz-lbl-detail {
  padding-left: 4px;
  padding-right: initial;
}
.injaz-main.ar-content span.injaz-program-label-detail {
  padding: 2px;
}

.injaz-main.ar-content span.injaz-lbl-detail:not(:last-child):after,
.injaz-main.ar-content span.injaz-lbl-detail:not(:last-child):after, 
.injaz-main.ar-content span.injaz-program-label-detail:not(:last-child):after {
  content: "،" !important;
}
p.testing {
  /* hide by default: */
  display: none;
}

#fortext1:checked~#textarea1,
#fortext2:checked~#textarea2,
#fortext3:checked~#textarea3 {
  display: block;
}
.panel-wrapper {
  position: relative;
}

.show.btn, .hide.btn {
  color: #005596;
  border-radius: 1.5em;
  padding: 0 1em 1em 0;
  text-decoration: none;
}
.show, .hide {
  position: absolute;
  bottom: -1em;
  z-index: 100;
  text-align: center;
}

.hide {display: none;}
.show:target {display: none;}
.show:target ~ .hide {display: block;}
.show:target ~ .panel {
   max-height: 2000px; 
}
.show:target ~ .fade {
  margin-top: 0;
}

.panel {
  position: relative;
  margin: 2em auto;
  max-height: 100px;
  overflow: hidden;
  transition: max-height .5s ease;
}
.fade {
  /*background: linear-gradient(to bottom, rgba($bg-color,0) 0%,rgba($bg-color,1) 75%);*/
  height: 100px;
  margin-top: -100px;
  position: relative;
}
.ui.pagination.menu {
  display: flex !important;
  justify-content: center !important;
  width: fit-content !important;
  margin: 0 auto !important;
}
.inj-home-rt-column {
  position: absolute !important;
}

/*Mobile*/
@media only screen and (max-width:767px){
  /*Home*/
.injaz-main .ui.container.appContent.injaz, .ui.container.injaz-main-public {
  background-position: top left !important;
}
.ar-content.injaz-main .ui.container.appContent.injaz, .ar-content.injaz-main .ui.container.injaz-main-public {
  background-position: top right !important;
}
.ui.container.appContent.injaz, .injaz-sidebar.menu .ui.container.sibebar-container .ui.container {
  width:auto!important;
  margin-left:0em!important;
  margin-right: 0em !important;
}
.injaz-forms-segment td.injaz-user-email {
  word-break: break-word;
}
.course-cert-info td {
  word-break: break-word;
}
.ui.overlay.right.very.wide.visible.sidebar.AddEditSessionSidebar, .injaz-main.ar-content .ui.overlay.left.very.wide.visible.sidebar.AddEditSessionSidebar {
  width: 100%;
}
.injaz-course-startenddate {
  display: block !important;
}
/*Forms*/
.ui.grid.injaz-form-pad>.row>[class*="eight wide"].column, 
.ui.grid.injaz-form-pad>.row>[class*="six wide"].column, 
.ui.grid.injaz-form-pad>.row>[class*="five wide"].column,
.ui.grid.injaz-form-pad>.row>[class*="equal wide"].column {
  width: 100% !important;
}
/*Tabs*/
.appContent.injaz .ui.attached.tabular.menu {
  display: block;
  background: none #fff;
  color: rgba(0, 0, 0, .95);
  border-top-width: 1px;
  border-color: #d4d4d5;
  font-weight: 700;
  margin-bottom: -1px;
  box-shadow: none;
  border-radius: .28571429rem .28571429rem 0 0 !important;
}
.appContent.injaz .ui.tabular.menu .active.item {
  background: none #edf1f5;
}
td {
  word-break: break-word;
}
span.injaz-course-end {
  padding-left: 0px !important;
  padding-top: 8px;
}
/*Table
  table.ui.table.course-cert-info {
    position: relative;
  }
  table.ui.table.course-cert-info thead, table.ui.table.course-cert-info tbody {
    vertical-align: top;
  }
  table.ui.table.course-cert-info thead, table.ui.table.course-cert-info tbody, table.ui.table.course-cert-info tr {
    display: table-cell;
  }
  table.ui.table.course-cert-info thead {
    width: 150px;
    border: 1px solid #999;
  }
  table.ui.table.course-cert-info tbody {
    position: absolute;
    left: 100px;
    width: 200px;
    border: 1px solid #e7e7e7;
    overflow-x: scroll;
  }
  table.ui.table.course-cert-info th, table.ui.table.course-cert-info td {
    display: block;
    width: 150px;
    height: 50px;
    padding: 10px 0;
  }*/
}
